import "./App.css";

const baseUrl = process.env.REACT_APP_API_URL;

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h3>FORM</h3>
        <form onSubmit={console.log}>
          <label htmlFor="email">Email</label>
          <input type="text" id="email" />
          <button>Subscribe</button>
        </form>
        <p>Your baseUrl is {baseUrl}</p>
      </header>
    </div>
  );
}

export default App;
